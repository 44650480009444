<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>CASE Items</h3>

	<p>The <b>items</b> in a framework’s CASE JSON represent competencies (e.g. learning standards) and organizational elements (e.g. a “cluster” of standards, or a set of standards for a particular “course” or “grade level”). Each item object specifies metadata including:</p>
	<ul>
		<li><b>Identifier</b> (required): <span v-html="link('identifiers', 'a globally unique identifier')"></span> meant to serve as an unambiguous reference to the item for any digital system that consumes the item’s CASE JSON</li>
		<li><b>URI</b> (required): a network-resolvable URI (usually a URL, meaning a resolvable web location) representing the item</li>
		<li><b>Full Statement</b> (required): the text of the item, for example the description of a standard or element (“Ask and answer questions about key details in a text.”), or the name of an organizational “branch” in the framework’s “tree” (“Speaking and Listening”).</li>
		<li><b>Human-Readable Code</b>: a string of characters, generated according to a framework-specific coding scheme determined by the framework author, allowing humans to easily refer to the item (as opposed to the item’s identifier GUID, which is difficult for humans to remember or refer to). <ul>
			<li>Note that it is not required for every item to have a human-readable code, and in fact in many frameworks, organizational branches do not have human-readable codes.</li>
		</ul></li>
		<li><b>Item Type</b>: a label for the type of competency this item represents. <ul>
			<li>While many frameworks use common item types such as “Standard”, “Element”, “Grade Level”, “Course”, etc., the vocabulary of item types used in any framework is up to the author of the framework to determine. Also note that it is not required that an item type be specified for any item.</li>
		</ul></li>
		<li><b>Notes</b>: a space for additional information about the item. (Different authors use this space for different purposes.)</li>
		<li><b>Language</b>: the language of the text used in the item’s full statement and other textual fields</li>
		<li><b>Education Level</b>: the primary instructional level(s) at which the item is intended. This can be a single level (e.g. 05 for grade 5) or a range of levels (e.g. [09,10,11,12] for a standard that may be taught in any US high school grade).</li>
		<li><b>Abbreviated Statement</b>: An abbreviated version of the full statement. (This field is not required, and if supplied, it is not defined when consuming systems should use the abbreviated statement as opposed to the full statement.)</li>
		<li><b>Last Changed Date</b>: The date and time when the item was last changed </li>
	</ul>
	<p>Note that none of the above are exhaustive lists of all fields that may be included in document, item, and association records. For full details see the official <a href="https://www.imsglobal.org/sites/default/files/CASE/casev1p0/information_model/caseservicev1p0_infomodelv1p0.html" target="_blank">CASE 1.0 Information Model</a>.</p>
	<p>Here’s what the CASE JSON looks like for a typical learning standard item:</p>
<pre style="font-size:10px; line-height:13px;">
{
  "uri": "https://state.standards.org/ims/case/v1p0/CFItems/36efc4c2-46fa-11e7-a537-04c350e66774",
  "identifier": "36efc4c2-46fa-11e7-a537-04c350e66774",
  "lastChangeDateTime": "2020-10-20T20:03:10+00:00",
  "CFItemType": "Content Standard",
  "educationLevel": ["03"],
  "humanCodingScheme": "RL.3.1",
  "fullStatement": "Ask and answer questions to demonstrate understanding of a text, referring explicitly to the text as the basis for the answers.",
  "language": "en",
}
</pre>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	